@import "./vars";


@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
}


.yel_1_header__side{
  display: flex;
  width: 60%;
  column-gap: 4px;
}

/* can delete*/
.yel_1_header_logo{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0 15px;
}

$backWave: #308FFA;
$frontWave: #419AFE;
$border: $frontWave;
$borderActive: var(--color-pink);

.upload {
    width: 66px;
    height: 66px;
    border-radius: 50%;
    position: relative;
    border: 2px solid $border;
    cursor: pointer;
    transform: translateZ(0);
    transition: transform .2s ease;
    .path {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        margin: -36px 0 0 -36px;
        width: 72px;
        height: 72px;
        .arrow {
          background: var(--color-1);
            bottom: 27px;
            width: 2px;
            height: 21px;
            position: absolute;
            left: 50%;
            border-radius: 1px;
            margin: 0 0 0 -1px;
            transform-origin: 50% 100%;
            &:before,
            &:after {
                content: '';
                display: block;
                width: 2px;
                height: 14px;
                bottom: -2px;
                background: var(--color-1);
                position: absolute;
                border-radius: 1px;
                transform-origin: 50% 100%;
            }
            &:before {
                right: 50%;
                transform: rotate(-44deg) translateX(2px);
            }
            &:after {
                left: 50%;
                transform: rotate(44deg) translateX(-2px);
            }
        }
        svg {
            width: 72px;
            height: 72px;
            display: block;
            fill: none;
            stroke: $borderActive;
            stroke-width: 2px;
            stroke-linecap: round;
            stroke-dashoffset: 592.73;
            stroke-dasharray: 0 592.73 20 592.73;
        }
    }
    .circle {
        position: absolute;
        width: 58px;
        height: 58px;
        margin: -29px 0 0 -29px;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        overflow: hidden;
        .water {
            transform: translateY(116%);
            background: $frontWave;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            svg {
                display: block;
                width: 116px;
                height: 6px;
                position: absolute;
                bottom: 100%;
                &:nth-child(1) {
                    right: 0;
                    fill: $backWave;
                    animation: forward 1.65s infinite;
                }
                &:nth-child(2) {
                    left: 0;
                    fill: $frontWave;
                    margin-bottom: -1px;
                    animation: backward .825s infinite linear;
                }
            }
        }
    }
    &.loading {
        .path {
            animation: movePath .4s linear forwards;
            .arrow {
                animation: arrow .5s linear forwards 3.7s;
                &:before {
                    animation: arrowB .4s linear forwards, arrowBCheck .5s linear forwards 3.7s;
                }
                &:after {
                    animation: arrowA .4s linear forwards, arrowACheck .5s linear forwards 3.7s;
                }
            }
            svg {
                animation: load 3s linear forwards .45s, reset .7s linear forwards 3.7s;
            }
        }
        .circle {
            .water {
                animation: fill 3s linear forwards .45s;
            }
        }
    }
    &:active {
        transform: scale(.92) translateZ(0);
    }
}

@keyframes load {
    0% {
        stroke-dashoffset: 592.73;
        stroke-dasharray: 0 592.73 20 592.73;
    }
    42% {
        stroke-dasharray: 0 592.73 80 592.73;
    }
    85% {
        stroke-dashoffset: 80;
        stroke-dasharray: 0 592.73 32 592.73;
    }
    100% {
        stroke-dashoffset: 32;
        stroke-dasharray: 0 592.73 32 592.73;
    }
}

@keyframes reset {
    0%,
    99% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes movePath {
    70% {
        transform: translateY(-10px);
    }
}

@keyframes arrow {
    0% {
        background: $borderActive;
        transform: rotate(34deg) translate(-2.5px, 2px);
    }
    40% {
        transform: rotate(-7deg) translate(0, 0);
    }
    99% {
        transform: rotate(0) translate(0, 0);
    }
    100% {
        background: $borderActive;
    }
}

@keyframes arrowBCheck {
    0%,
    20% {
        transform: rotate(-86deg) translateX(2px) translateY(1px) scaleY(.714);
        opacity: 1;
    }
    100% {
        transform: rotate(-44deg) translateX(2px) scaleY(1) translateY(0);
        opacity: 1;
    }
}

@keyframes arrowACheck {
    0% {
        transform: rotate(0deg) translate(-1px, -1px);
        opacity: 1;
    }
    40% {
        transform: rotate(60deg) translate(-2px, 1px);
        opacity: 1;
    }
    100% {
        transform: rotate(44deg) translateX(-2px);
        opacity: 1;
    }
}

@keyframes arrowB {
    0% {
        transform: rotate(-44deg) translateX(2px);
    }
    60% {
        transform: rotate(-60deg) translateX(2px);
    }
    99% {
        transform: rotate(0deg) translateX(1px);
        opacity: 1;
    }
    100% {
        transform: rotate(0deg) translateX(1px);
        opacity: 0;
    }
}

@keyframes arrowA {
    0% {
        transform: rotate(44deg) translateX(-2px);
    }
    60% {
        transform: rotate(60deg) translateX(-2px);
    }
    99% {
        transform: rotate(0deg) translateX(-1px);
        opacity: 1;
    }
    100% {
        transform: rotate(0deg) translateX(-1px);
        opacity: 0;
    }
}

@keyframes fill {
    0% {
        transform: translateY(116%);
    }
    80% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
}

@keyframes backward {
    100% {
        transform: translateX(-50%);
    }
}

@keyframes forward {
    100% {
        transform: translateX(50%);
    }
}

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

* {
    box-sizing: inherit;
    &:before,
    &:after {
        box-sizing: inherit;
    }
}

// Center & dribbble
body {
    .dribbble {
        position: fixed;
        display: block;
        right: 24px;
        bottom: 24px;
        img {
            display: block;
            width: 76px;
        }
    }
}




.yel_1_bluePopular_banana{
  width: 80px;
  height: 80px;
}

.ova-menu-decor-icon svg{
  width: 135px;
}

.yel_1_search{
  z-index: 2;
}
.yel_1_description_content{
  text-align: center;
}

.ya-share2 .ya-share2_inited{
  margin-left: 5px !important; 
}

.ova-menu-decor-icon{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.ya-share2__container_mobile .ya-share2__badge{
  border-radius: 5px !important;
  margin-right: 7px !important;
}

.yel_1_share{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}


.yel_1_header_logo-img{
  width: 70px;
  height: 70px;
}
/* can delete*/

.yel_1_card_name{
  margin-right: 230px;
      max-width: calc(100% - 120px);
}

.yel_1-body{
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
}

.yel_1_input-content{
  color: var(--color-pink);
}

.yel_1_input-content:focus{
  letter-spacing: 1.3px;
}

ul{
  padding: 0;
}




html,
body {
  background-color: var(--color-4);
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Merriweather", serif;
  font-size: 16px;
  letter-spacing: 1.4px;
}


.yel_1_story-share{
  display: flex;
  justify-content: center;
}

/*Border Component END*/

input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease;}
input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

.yel_1_blueSocial{
  width: 200px;
  display: flex;
  justify-content: space-between;
}

.yel_1_blueSite{
  display: flex;
}

.yel_1_input{
  
  width: 100%;
&-content{
  background-color: var(--color-1);
  font-size: 26px;
  border: none;
  width: 100%;
  &:hover,&:focus, &:active {
    border: none;
    outline: 0;
    outline-offset: 0;
  }
  &::placeholder{
    font-weight: 700;
    color: var(--color-2);
  }
}
}

.yel_1_letter__cnt{
  margin-top: 40px;
}

.yel_1_letter{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  &:hover{
    cursor: pointer;
    .yel_1_letter__cnt{
      display: block;
      position: absolute;
      background: var(--color-4);
      padding: 20px;
      border-radius: 10px;
      
    }
  }
  &__link{
    color: #fff;
    font-size: 18px;
    margin: 6px;
    &:hover{
      text-decoration: underline;
      font-weight: 700;
    }
  }
  &__heading{
    display: flex;
    align-items: center;
  }
  &__title{
    margin: 0;
    display: flex;
    color: var(--color-3);
    font-size: 24px;
  }
  &__logo{
    margin-left: 10px;
    fill: var(--color-3);
    width: 38px;
    height: 38px;
  }
}

.yel_1_big_Container{
  background: url('/static/theme17/images/fore.jpg');
}

.yel_1_blueContainer {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
  background: var(--color-4);
  max-width: 90%;
}

.yel_1_sotial-network-vk{
  display: flex;
  flex-direction: column-reverse;
}

.yel_1_mobile__button{
  color: var(--color-3);
  display: flex;
  align-items: center;
  font-size: 20px;
  position: relative;
  
  padding: 25px 50px;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 2.5px;
  justify-content: center;
  text-align: center;
  &:hover{
    background-color: var(--color-1);
      .yel_1_mobile__button-line{
        background-color: var(--color-pink);
      }
  }
}

.yel_1_letter{
  padding: 10px;
}
.yel_1_header{
  &_menu{
  
        display: flex;
        flex-wrap: wrap;
        column-gap: 30px;
        justify-content: space-between;
        margin-right: auto;
        margin-left: auto;
        margin-top: 30px;
        row-gap: 30px;
  }
  &_menu a{
    border-radius: 60px;
    color: var(--color-1);
    display: flex;
    align-items: center;
    background: var(--color-pink);
    font-size: 18px;
    position: relative;
    padding: 20px 30px;
    text-transform: uppercase;
    font-weight: 700;
    justify-content: center;
    text-align: center;
    &:hover{
      background-color: var(--color-lime);
      // color: var(--color-pink);
    }
    
    
  }

  &_inner{
    width: 100%;

  }
  &_logo-link{
    font-size: 33px;
    font-weight: 700;
    color: var(--color-1);
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}


.yel_1_header{
  background: var(--color-pink);
  padding: 30px 0;
}

.yel_1_info-block__inform{
  display: flex;
  column-gap: 5px;
}


.yel_1_mobile__button{
  background: var(--color-pink);
  cursor: pointer;
  padding: 0;
  border: none;
  position: relative;
  z-index: 9999999;
  width: 110px;
  border: 4px solid var(--color-1);
  flex-direction: column;
  border-radius: 7px;
  &-line{
    margin-bottom: 5px;
    margin-top: 3px;
    display: block;
    position: relative;
    width: 46px;
    height: 5px;
    border-radius: 12px;
    background-color: var(--color-1);
  }
}

.yel_1_blueWrapper{
  flex-flow: wrap;
  display: flex;
  flex-direction: column;
  width : 100%
}

.yel_1_bluePopular{
  &__box{
    justify-content: center;
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: -30px;
  }
  &_title{
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: 700;
    color: #fff;
    font-size: 30px;
  }
}

// .uni{
//   display: none !important;
// }

.pages{
  color: var(--color2);
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  font-weight: 700;
  & a{
    color: var(--color2);
    &:hover{
      text-decoration: underline;
    }
  }

}

.yel_1_result-of-search:hover{
  text-transform: uppercase;
}

.active{
  display: flex !important;
}

.yel_1_result-of-search{
  padding: 20px 0;
  cursor: pointer;
  width: 100%;
  position: relative;
  display: flex;
  border-bottom: 3px solid var(--color-4);
  &_link{
    font-size: 22px;
    color: #fff;
    margin-left: 20px;
  }
  &_box{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.limport_ser-result-of-search_box{
  width: 100%;
}

.yel_1_card:hover{
  .share{
    display: flex;
  }
}

.share{
  position: absolute;
  right: 200px;
  & svg{
    width: 40px;
    height: 40px;
  }
}

.yel_1{
  align-items: center;
  display: flex;
  flex-direction: column;
  p{
    font-size: 20px;
    text-align: center;
  }
  &_card_artist, &_card_tracklink{
    overflow: hidden;
    max-height: 26px;
  }
  &_card{
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 17px;
    display: flex;
    align-items: center;
    position: relative;
    padding: 8px;
    // border-radius: 7px;
    // border-top: 3px solid var(--color-1);
    border-bottom: 3px solid var(--color-1);
    &:hover{
      background-color: var(--color-1);
      border: 3px solid var(--color-lime);
      border-radius: 7px;
      .yel_1_card_artist, .yel_1_card_tracklink, .dur{
        color: #308FFA;
      }
      .upload .path .arrow, .upload .path .arrow:after, .upload .path .arrow:before{
        background: var(--color2);
      }
    }
    &_artist{
      margin-right: 5px;
    }
     
  
  }
  &_card.pausing a.yel_1_card_link::before{
    content: url('../images/card-play.svg');
  }
  &_card.plays a.yel_1_card_link::before{
    content: url('../images/card-pause.svg');
  }
  &_card_like{
    height: 30px;
  }
  &_card .start {
    cursor: pointer;
} 
  &_card-subtext{
    position: absolute;
    right: 130px;
  }

  &_card_time{
    font-size: 16px;
    color: var(--color-1);
  }
  &_card_share{
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;
  }
  &_card_artist {
    font-size: 18px;
    color: #fff;
    width: 100%;
    margin-right: 20px;
  }
  &_card_tracklink{
    color: #fff;
    font-weight: 700;
  }
  &_card_download{
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    svg{
      width: 65px;
      height: 65px;
    }
  }
  &_card_link{
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    margin-right: 15px;
    margin-left: 10px;
    color: var(--white-color);

    &::before {
      content: url('../images/card-play.svg');
      width: 70px;
      height: 70px;
    }
  }
}



.yel_1_card_artist::after{
  display: none;
  content: ' - ';
  margin-left: 20px;
}

.yel_1_music{
  &__title{
    display: flex;
    align-items: center;
    column-gap: 20px;
    justify-content: center;
  }
  &__title-heading{
    text-align: center;
    color: var(--color-1);
    font-size: 25px;
    // &::after{
    //   content: '';
    //   background: var(--color-1);
    //   border-radius: 50px;
    //   position: absolute;
    //   bottom: -7px;
    //   right: 0;
    //   width: 14px;
    //   height: 14px;
    // }
  }
  &__title-logo{
    width: 32px;
    height: 36px;
    fill: var(--color-1);
    margin-right: 30px;
  }
}

.submit {
  cursor: pointer;
    transition: all .2s ease;
    border: none;
    background: 0 0;
    height: 100%;
    padding-left: 15px;
    display: flex;
    align-items: center;
  svg {
    width: 45px;
    height: 45px;
    transition: all 0.6s;
    fill: var(--color2);
  }
}

.dur {
  font-size: 19px;
  color: #fff;
}

.title {
 overflow: hidden;
}

.yel_1_logo-link{
  font-size: 35px;
  font-weight: 700;
  color: var(--color-1);
}

.yel_1_footer{
  margin-bottom: 170px;
  &_wrapper {
    font-size: 20px;
    position: relative;
    color: var(--color-1);
  }
  &_copy{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.yel_1_player-link{
  font-size: 30px;
  color: #fff;
}

.yel_1_story_track-title{
  width: calc(100% - 35px);
  word-wrap: break-word;
  font-weight: 700;
}

.yel_1_story{
  &_heading{
    text-align: center;
    color: #fff;
    font-size: 24px;
  }
  &_track{
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 20px;
  }
  &_track-link{
    font-size: 15px;
    color: #fff;
    padding: 15px 20px;
    font-weight: 400;
    border-radius: 90px;
    background: var(--color-pink);
    &:hover{
      letter-spacing: 2.1px;
      background: var(--color-lime);
    }
  }
  &__track-img{
    height: 50%;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;  
    color: var(--white-color);
    i{
      font-size: 30px;
    }
  }
  &__track-title{
    font-size: 20px;
    font-weight: 700;
  }
}

.yel_1_info-block{
    margin-bottom: 20px;
    background: var(--color2);
    padding-left: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    height: 55px;
    margin-bottom: 20px;
  &__author{
    font-size: 18px;
  }
}

.title{
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.yel_1_description{
  width: 450px;
  font-size: 22px;
  &_content{
    padding-top: 30px;
    color: #fff;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 22px;
    margin-bottom: 20px;
  }
  &_icon{
    font-size: 25px;
    margin-right: 10px;
  }
  &_title{
    display: flex;
    } 
  }

.seo-title--footer{
  justify-content: center;
  width: 100%;
  display: flex;
}

.yel_1_boxline{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.yel_1_copyright_box{
 padding-bottom: 30px;
  &-logo{
    padding: 0 10px;
  }
  &-mail{
    color: var(--color-1);
    column-gap: 10px;
    display: flex;
    justify-content: center;
    font-size: 24px;
    align-items: center;
    a{
      color: var(--color-1);
    }
  }
  &-link{
    color: var(--color-1);
  }
}

.yel_1_social-box--btnBox {
  flex-wrap: wrap;
  position: relative;
  padding: 5px;
}

.about{
  &__inner{
    margin-bottom: 20px;
    padding: 5px;
    color: var(--color-2);
    
  }
}

.boxline{
  margin: 10px 0;
  &__top p{
    line-height: 1.5;
    font-size: 20px;
    color: #fff;
  }
  &__bottom{
    font-size: 20px;
    font-weight: 700;
    color: var(--white-color);
    text-align: center;
  }
}

 .yel_1_sotial-network-vk {
  &_btn{
    border-radius: 10px;
    height: 50px;
    background-color: var(--color2);
    width: 100%;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    &::placeholder{
      color: #fff;
    }
  }
  &_link{
    border-radius: 10px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 700;
    border: 4px solid var(--color2);
    color: var(--color2);
    position: relative;
    overflow: hidden;
    z-index: 1;
    -webkit-transition: color 150ms ease-in-out;
    transition: color 150ms ease-in-out;
    height: 50px;
    display: flex;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
    &:after {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: var(--color-4);
      
      z-index: -1;
      -webkit-transition: width 150ms ease-in-out;
      transition: width 150ms ease-in-out;
    }
    &:hover::after{
      width: 110%;
      border-color: var(--color-4) ;
      
    }
    &:hover{
      color: #fff;
    }
  }
  &_input{
    padding: 0 15px;
    margin-bottom: 20px;
      display: flex;
      flex-direction: column;
  }
  &_content{
    line-height: 2;
    font-size: 20px;
    color: var(--color2);
    font-weight: 700;
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--color-1);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--color-1);
  font-size: 18px;
  margin-bottom: 5px;
} 

#genre{
  padding: 25px 0 0;
  list-style-type: none;
  position: relative;
}

.yel_1_genre{
  &-list{
    display: flex;
    flex-wrap: wrap;
  }
  &-item{
    font-weight: 400;
    display: flex;
    padding-bottom: 0;
    // &:hover{
    //   border-bottom: 3px solid var(--color-4);
    //   text-transform: uppercase;
    // }
    // &::before{
    //   content: ">";
    //   color: var(--color-4);
    //   font-size: 20px;
    //   margin-right: 10px
    //   }
    &_link{
      font-size: 15px;
      color: #fff;
      padding: 15px 20px;
      font-weight: 400;
      border-radius: 90px;
      background: var(--color-pink);
      &:hover{
        letter-spacing: 2.1px;
        background: var(--color-lime);
      }
    }
  }
  &-item_logo{
    margin-right: 15px;
    font-size: 25px;
    color: var(--color-14);
  }
}
.active__selection{
  list-style: none;
}

#trackAjax {
  z-index: 1;
  padding: 0;
}

.yel_1_search{
  width: 100%;
}

#yel_1_search__form {
  padding: 10px;
  display: flex;
  border-radius: 7px;
  align-items: center;
  background: var(--color-1);
  height: auto;
  width: 100%;
  box-sizing: border-box;
}



.pleer {
  z-index: 99999;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
  transition: all .6s ease;
  padding-bottom: 15px;
  background-color: var(--color-pink);
}



.yel_1_volume {
  margin-left: 5px;
  cursor: pointer;
    padding: 0;
    overflow: hidden;
    width: 150px;
    height: 15px;
    border: 3px solid #fff;
    margin-top: 0;
    border-radius: 7px;
    z-index: 202;
    position: relative;
    
  .yel_1_volume__line {
    background: #fff;
    position: absolute;
    top: 0;
    cursor: pointer;
    width: 25%;
    height: 15px;
    float: left;
    -webkit-transition: width 0.7s ease;
    transition: width 0.7s ease;
    border-radius: 5px;
  }
}



.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

a.play {
  color: var(--white-color);
  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--white-color);
}


.play,.pause{
  display: flex;
  align-items: center;
  justify-content: center;
}
.play {
  &::before {
    content: url("../images/card-play.svg");
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }
}
.pause {
  &::before {
    content: url('../images/card-pause.svg');
    width: 80px;
    height: 80px;
    margin: 0 10px;
  }
}

.yel_1_track-central_left-btn,
.yel_1_track-central_right-btn{
  svg{
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  fill: var(--color-1);
  
  }
}

.yel_1_player-title, .yel_1_mute-box{
  width: 30%;
}

.yel_1_letter__cnt{
  
  display: none;
  right: 0;
  width: 250px;
  z-index: 3;
}

.yel_1_track-central_left-btn svg{
  transform: rotate(180deg);
}

.uil{
  cursor: pointer;
}

.yel_1_track-central{
  width: 30%;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  display: flex;
  z-index: 2;
  margin-right: 30px;
}

.progress-bar__container-playbar{
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--color-2);
  color: var(--color-2);
}

.progressBarContainer--downloadBar{
  display: block;
  position: absolute;
  height: 70px;
  width: 0;
}

.download-bar{
  display: block;
  height: 70px;
  width: 100px;
  z-index: 201;
  border-radius: 20px;
}

.progress-bar__container-playbar {
  position: absolute;
  left: 0;
  height: 15px;
  display: inline-block;
  background: var(--color-1);
  align-items: center;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--white-color);
    border: 2px solid #061161;
    display: none;
  }
}

.track {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 30px 0;
  width: 100%;
}

.track__track-timebox {
  position: absolute;
  width: 100%;
  height: 15px;
  background: var(--color2);
}

.progress-bar__container {
  cursor: pointer;
  transition: height linear 0.3s;
  height: 15px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}


.yel_1_zero-time {
  font-size: 14px;
}

.yel_1_timeBox {
  margin-right: 18px;
  font-size: 14px;
}

.yel_1_player-title_heading {
  z-index: 2;
  text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    align-items: center;
    font-weight: 700;
    flex-wrap: wrap;
    overflow: hidden;
    font-size: 22px;
    margin-right: 20px;
    margin-left: 0;
    color: #fff;
}

.yel_1_mute-box {
  justify-content: flex-end;
  display: flex;
  height: 100%;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--white-color);
    }
  }

  &__muteUnmute{
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.loaded {
  display: none;
}


.yel_1_time{
  &_space{
    font-weight: 700;
    margin: 0 5px;
  }
  display: flex;
  color: var(--color-4);
  position: absolute;
  left: 10px;
  width: 100%;
  z-index: 2;
  top: 0;
  bottom: 0;
  align-items: center;
  justify-content: space-between;
}

.yel_1_track__leftWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  .player__Collapse{
    display: none;
  }
}

.yel_1_playerTime__box{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.yel_1_xplayer-title{
  width: 100%;
  display: flex;
  align-items: center;
}

.yel_1_player-title_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  svg {
    width: 40px;
    height: 30px;
    fill: var(--color-14);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  padding: 2px 5px 2px 0;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.yel_1_mute,
.yel_1_unmute{
  svg{
    position: relative;
    display: inline-block;
    width: 45px;
    height: 45px;
  }
}
.yel_1_mute{
  fill: var(--color-1);
}
.yel_1_unmute{
  fill: none;
}



#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}




/*SHAREBOX*/
span{
  &.yel_1_shareblock{
    border-radius: 15px;
    z-index: 100000;
    width: 300px;
    display: block;
    background-color: var(--color-pink);
    position: fixed;
    top: 50%;
    left: 50%;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 5px;
    text-align: center;
    border: 3px solid var(--color-3);
    b {
    position: absolute;
    top: -25px;
    right: -20px;
    display: block;
    width: 40px;
    height: 40px;
    text-align: center;
    margin-bottom: -25px;
    background-color: #fff;
    cursor: pointer;
    border-radius: 30px;
    line-height: 38px;
    font-size: 30px;
    box-sizing: border-box;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-4);
      font-family: sans-serif;
      font-size: 20px;
      font-weight: 400;
      display: block;
      color: #fff;
      margin-bottom: 20px;
      border-bottom: 4px solid var(--color-1);
    }
    span {
      width: 120px;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    a {
      margin-bottom: 20px;
      &.vk {
        background-position: 0 0px;
      }
      &.tw {
        background-position: 0 -56px;
      }
      &.ok {
        background-position: 0 -86px;
      }
      &.mm {
        background-position: 0 -116px;
      }
      &.gp {
        background-position: 0 -146px;
      }
    }
  }
}

.yel_1_blueSocial_vk a{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yel_1_blueSocial_vk {
  width: 200px;
  height: 34px;
  display: inline-block;
  padding: .75rem 1.25rem;
  border-radius: 15px;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  letter-spacing: .15rem;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color2);
    border-radius: 15px;
    z-index: -2;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--color-4);
    transition: all .3s;
    border-radius: 15px;
    z-index: -1;
  }
  &:hover {
    color: #fff;
    &:before {
      width: 100%;
    }
  }
}

.yel_1_blueDownload_vk {
  line-height: 50px;
	text-align: center;
	cursor: pointer;
	color: #FFF;
	transition: all 0.3s;
  
}
.yel_1_blueDownload_vk span {
	transition: all 0.3s;
  font-weight: 700;
  font-size: 16px;
}


.yel_1_blueDownload_vk:hover span {
	letter-spacing: 2px;
}
.yel_1_blueDownload_vk:hover::before {
	opacity: 1;	
}

.yel_1_blueDownload_vk:hover::after {
	opacity: 0;	
}



.yel_1_active__selection{
  &-content{
  display: flex;
  &:hover{
    & a{
      color: var(--color-1) !important;
    }
    .yel_1_active__selection-like{
      fill: var(--color-1);
    }
  }
  a{
    color: var(--color-14);
  }
}
  &-like{
    fill: var(--color-14);
    width: 40px;
    height: 40px;
    margin-left: 20px;
  }
}


.yandex__share{
  display: none;
}

.yel_1_blueSidebar{
  &_list{
    flex-direction: column;
    width: 300px;
    display: flex;
    margin-left: 40px;
  }
  & ul{
    column-gap: 20px;
    row-gap: 20px;
    font-weight: 700;
    margin-bottom: 0;
    margin-bottom: 30px;
  }
  .yel_1_sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--color-10);
    &_logo{
      a{
        color: var(--white-color);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--white-color);
      padding: 10px;
      font-size: 18px;
    }
  
  }
  a[aria-expanded="true"] {
    position: relative;
    font-size: 35px;
  }
  a[data-toggle="collapse"] {
    position: relative;
  }
  a.article {
    color: var(--white-color) !important;
    &:hover {
      background: var(--color-10) !important;
      color: var(--white-color) !important;
    }
  }

}


