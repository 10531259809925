@import './vars';


@media screen and (max-width: 1440px) {
  .yel_1_mute-box{
    width: 30%;
  }
  .yel_1_track-central{
    margin-right: 0;
  }
  #yel_1_search__form {
    padding: 15px;
  }
  .title {
    padding-left: 25px;
    max-width: 400px;
  }
  .yel_1_bluePopular_title {
    justify-content: center;
    display: inline-block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .yel_1_card_artist, .yel_1_card_tracklink {
    max-height: 42px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .seo-title--footer {
    justify-content: center;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .yel_1_player-title_heading {
    max-width: 420px;
  }
}

@media screen and (max-width: 1200px) {
  .yel_1_letter {
    display: none;
  }
  .yel_1_mobile__button {
    display: flex;
  }
  .yel_1_share {
    margin-left: 0;
  }
  .yel_1_blueWrapper {
    margin-left: 0;
    color: var(--color-1);
  }
  .yel_1_info-block {
    margin-right: -15px;
  }
  .pleer {
    left: 0;
    right: 0;
  }
  .yel_1_player-title_heading {
    max-width: 420px;
  }
}

@media screen and (max-width: 1024px) {
  .yel_1_player-title{
    width: auto;
  }
  .yel_1_mute-box{
    display: none;
  }
  .yel_1_card_artist, .yel_1_card_tracklink{
    font-size: 14px;
  }
  .yel_1_bluePopular{
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .yel_1_bluePopular_title{
    font-size: 22px;
  }
  .yel_1_blueContainer{
    max-width: none;
  }
  .yel_1_header__side{
    width: 100%;
  }
  .yel_1_header_logo{
    flex-direction: column;
    row-gap: 20px;
  }
  .yel_1_info-block__author-1 {
    display: none;
  }

  .seo-title--footer {
    margin-left: 0;
  }
  .yel_1_copyright_box {
    margin-left: 0;
  }
  .yel_1_description_title {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title{
    max-width: 400px;
  }
}

@media screen and (max-width: 992px) {
  
}

@media screen and (max-width: 920px) {
  .yel_1_copyright_box-mail{
    column-gap: 10px;
  }
  .yel_1_player-title_heading {
    max-width: 420px;
  }
  .title {
    max-width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .yandex__share{
    display: flex;
    justify-content: center;
  }
  .yel_1_story{
    display: none;
  }
  .yel_1_genre-item{
    width: calc(33% - 20px);
  }
  .yel_1_genre-item_link{
    width: 100%;
  }
  .yel_1_copyright_box-mail{
    flex-direction: column;
  }
  .upload{
    width: 60px;
    height: 60px;
  }
  .yel_1_header_menu{
    row-gap: 20px;
    margin-top: 20px;
  }
  .yel_1_header_menu a{
    font-size: 16px;
    text-transform: none;
    padding: 15px;
    width: calc(50% - 15px);
  }
  .yel_1_header{
    padding: 15px 0;
  }
  .submit svg{
    width: 40px;
    height: 40px;
  }
  #yel_1_search__form{
    padding: 5px;
  }
  .yel_1_input-content{
    font-size: 22px;
  }
  .yel_1_header_logo-link{
    font-size: 28px;
  }
  .title{
    max-width: calc(100% - 50px);
  }
  .share{
    right: 120px;
  }
  

  .norm{
    display: none !important;
  }
  .uni{
    display: flex !important;
    background-color: inherit;
    position: absolute !important;
    right: 50px;
    z-index: 999999999;
  }
 
  .yel_1_bluePopular{
    margin-bottom: 25px;
    margin-top: 25px;
  }
  span.yel_1_shareblock{
    left: auto;
    right: 20px;
  }
  .yel_1_card-subtext{
    right: 100px;
  }
  .yel_1_card_download svg{
    width: 55px;
    height: 55px;
  }
  .yel_1_card_link::before{
    width: 60px;
    height: 60px;
  }
  
  .title{
    padding-left: 0;
  }
  .yel_1_card_artist {
    margin-right: 5px;
  }
  .yel_1_card_artist::after {
    margin-left: 5px;
  }
  .yel_1_blueSocial_vk {
    display: none;
  }
  .yel_1_card_time {
    display: none;
  }
  .ova-menu-decor-icon {
    display: none;
  }

  .yel_1_share {
    margin-bottom: 0;
  }
  .yel_1_card_name {
    margin-right: 100px;
  }
  .share, .yel_1_info-block__author-2 {
    display: none;
  }
  .yel_1_mute-box {
    display: none;
  }

  #yel_1_search__form {
    width: auto;
  }
  
  .pleer {
    width: 100%;
    box-sizing: border-box;
  }
  .play::before, .pause::before {
    margin: 0 7px;
  }
  .yel_1_story_track{
    row-gap: 15px;
    column-gap: 15px;
  }
  .yel_1_story_track-link {
      width: calc(33% - 11px);
      justify-content: center;
  }
  .title{
    max-width: 314px;
  }
}

@media screen and (max-width: 624px) {
  .yel_1_genre-item{
    width: calc(50% - 10px);
  }

  .yel_1_story_track-link{
    width: 100%;
  }
  .yel_1_bluePopular_banana{
    width: 60px;
    height: 60px;
  }
  .share{
    right: 65px;
  }
  .share svg{
    width: 30px;
    height: 30px;
  }
  .uni{
    right: 20px;
  }
  .yel_1_mobile__button .uni{
    padding: 0;
    padding-left: 5px;
  }
  .yel_1_blueContainer{
    max-width: 95%;
  }
  .yel_1_copyright_box-mail {
    flex-direction: column;
    text-align: center;
  }
  .yel_1_copyright_box {
    margin-bottom: 60px;
  }
  .yel_1_player-title_heading {
    margin-right: 0;
    font-size: 16px;
  }
  .yel_1_player-title_heading {
    text-align: center;
  }
  .yel_1_track-central {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .yel_1_card_download {
    width: 40px;
    height: 40px;
  }
  .submit svg {
    width: 30px;
    height: 30px;
  }
  .yel_1_input-content {
    font-size: 17px;
  }
  #yel_1_search__form {
    padding: 10px;
    border-radius: 10px;
  }
  .yel_1_info-block {
    margin-bottom: 10px;
    height: 45px;
    padding-left: 35px;
  }
  .yel_1_info-block__author {
    font-size: 16px;
  }
  .yel_1_card_link::before, .upload {
    width: 40px;
    height: 40px;
  }
  .yel_1_card_artist, .yel_1_card_tracklink {
    font-size: 15px;
  }
  .yel_1_card_name {
    margin-right: 60px;
  }
  .yel_1_card{
    padding: 10px 0;
  }
  .yel_1_card_download svg {
    width: 40px;
    height: 40px;
  }
  .title{
    row-gap: 4px;
  }
  .yel_1_card_download, .yel_1_card_link {
    right: 10px;
  }
  
  .track {
    flex-direction: column;
  }
  .social-box {
    right: 30px;
  }

}

@media screen and (max-width: 670px) {
  .yel_1_copyright_box-mail{
    font-size: 20px;
  }
  .import_ser-melodious__list a {
    justify-content: center;
  }
}

@media screen and (max-width: 577px) {

  
  .yel_1_story_track-link {
    max-width: 453px;
  }

 
}


@media screen and (max-width: 510px) {
  .yel_1_blueContainer{
    max-width: 100%;
    margin: 0;
  }
  .yel_1_card-subtext{
    right: 70px;
  }
  .ya-share2__list.ya-share2__list_direction_horizontal{
    margin-top: 0 !important;
  }
  .yel_1_blueContainer{
    padding: 5px;
  }
  .yel_1_card_download, .yel_1_card_link{
    right: 5px;
  }
  .yel_1_player-title_heading {
    font-size: 16px;
  }
  .yel_1_track-central_left-btn svg, .yel_1_track-central_right-btn svg {
    width: 30px;
    height: 30px;
  }
  .pause::before, .play::before {
    width: 60px;
    height: 60px;
  }
}

@media screen and (max-width: 470px) {
  .yel_1_card_artist, .yel_1_card_tracklink{
    font-size: 13px;
  }
  .yel_1_header_logo-link {
    font-size: 26px;
  }

}

@media screen and (max-width: 425px) {
  .yel_1_genre-item{
    width: 100%;
  }
  .title{
    max-width: none;
  }
  .uni{
    right: 10px;
  }
  .share{
    display: none !important;
  }
  .yel_1_blueContainer {
    padding: 0 8px;
  }

  .yel_1_mobile__button-line {
    width: 35px;
    height: 3px;
  }
  .yel_1_header_logo-link {
    font-size: 24px;
  }
  
  .submit {
    padding-left: 0;
    padding-right: 0;
  }

  .ya-share2__container {
    display: none;
  }
  .music-artist__card_artist, .music-artist__card_tracklink {
    max-height: 17px;
  }
  .music-artist__card_download svg {
    width: 40px;
    height: 25px;
  }
  .music-artist__card_download {
    width: 40px;
    height: 20px;
  }
  .title {
    font-size: 14px;
  }
  .music-artist__card {
    height: 70px;
    padding: 10px 5px 5px 5px;
  }
  .music-artist__card_time {
    display: none;
  }
  .music-artist__card .start {
    transform: scale(0.6);
  }
  .music-artist__card_download {
    position: static;
  }
  .music-artist__card-subtext {
    display: none;
  }
  .res-card_st-bf_results-info__card {
    padding: 15px 10px;
  }
  .import_ser-results-info__card_artist, .import_ser-results-info__card_space, .import_ser-results-info__card_tracklink {
    font-size: 14px;
  }
  .import_ser-results-info__card_like {
    display: none;
  }

  .import_ser-results-info__card_download svg {
    width: 30px;
    height: 30px;
  }
  .yel_1_mobile__button .uni{
    margin-right: 0;
  }

  .yel_1_description_content {
    font-size: 18px;
    padding-left: 5px;
  }
  .yel_1_description_title {
    max-width: 323px;
  }
  .seo-title--footer {
    justify-content: center;
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .yel_1_player-title_heading {
    max-width: 313px;
  }
  .yel_1_bluePopular_title {
    font-size: 20px;
  }
}
@media screen and (max-width: 390px) {
  .yel_1_header_menu a{
    width: 100%;
  }
  .yel_1_story_track-link {
    max-width: 300px;
  }

  .yel_1_header_logo-link {
    font-size: 24px;
    font-weight: 900;
    display: flex;
    align-items: center;
  }
}
@media screen and (max-width: 375px) {
  .yel_1_player-title_heading{

    margin-bottom: 10px;
  }
  .track{
    padding-top: 20px;
  }
  .yel_1_header_logo{
    padding: 0 5px;
  }
  .yel_1_input-content {
    width: 210px;
  }
  .ova-menu-decor-icon svg {
    margin-right: 10px;
    width: 30px;
    height: 30px;
  }
  .yel_1_story_track-link {
    max-width: 238px;
  }
  
  .yel_1_header_logo-link {
    font-size: 20px;
    column-gap: 6px;
  }


  .yel_1_player-title_heading {
    max-width: 256px;
  }
  .yel_1_bluePopular_title {
    font-size: 16px;
  }
  .yel_1_bluePopular_banana {
    width:  50px;
    height: 50px;
  }
} 